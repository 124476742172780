img {
  width: 100%;
  pointer-events: none;
  vertical-align: middle;
}
#widgetable-wrapper {
  width: 100vw;
  min-height: 100vh;
  color: #fff;
  box-sizing: border-box;
}

#widgetable-wrapper .download-ios {
  background: url(./images/download-ios.png) no-repeat center/contain;
}

#widgetable-wrapper .download-android {
  background: url(./images/google-play.png) no-repeat center/contain;
}

/* H5 */
@media screen and (max-width: 900px) {
  #widgetable-wrapper {
    background: url(./images/bg-h5.jpeg) no-repeat center/cover;
    padding-top: 2em;
  }

  .logo {
    margin: auto;
    width: 55%;
  }
  .slogen {
    width: 90%;
    margin: 1.2rem auto 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
  }
  .example {
    margin: auto;
  }
  .download-ios {
    width: 11rem;
    height: 3.2rem;
    margin: 2.5em auto;
  }

  .download-android {
    width: 11rem;
    height: 3.2rem;
    margin: 2.5em auto;
  }

  footer {
    color: rgba(255, 255, 255, 0.45);
    text-align: center;
    line-height: 2em;
    margin: auto;
    font-size: 0.8rem;
  }
  footer a {
    color: rgba(255, 255, 255, 0.45);
    text-decoration: underline;
  }
  footer .link-policy {
    margin-right: 1em;
  }
  footer .link-terms {
    margin-left: 1em;
  }
}

/* PC */
@media screen and (orientation: landscape) and (min-width: 900px) {
  #widgetable-wrapper {
    min-width: 980px;
    background: url(./images/bg-pc.jpeg) no-repeat center/cover;
    color: #fff;
    position: relative;
  }
  .logo {
    width: 260px;
    position: absolute;
    top: 16%;
    left: 90px;
  }
  .slogen {
    width: 45%;
    line-height: 1.3;
    font-size: 50px;
    font-weight: bold;
    position: absolute;
    top: 45%;
    left: 90px;
  }
  .example {
    width: 35%;
    min-width: 400px;
    position: absolute;
    top: 12.5%;
    right: 60px;
  }
  .download-ios {
    width: 230px;
    height: 68px;
    position: absolute;
    top: 68%;
    left: 90px;
    cursor: pointer;
  }

  .download-android{
    width: 230px;
    height: 68px;
    position: absolute;
    top: 68%;
    left: 350px;
    cursor: pointer;
  }
  footer {
    color: rgba(255, 255, 255, 0.45);
    text-align: center;
    line-height: 2em;
    font-size: 0.82rem;
    position: absolute;
    bottom: 1%;
    left: 0;
    right: 0;
    margin: auto;
  }
  footer a {
    color: rgba(255, 255, 255, 0.45);
    text-decoration: underline;
  }
  footer .link-wrapper {
    display: inline-block;
    margin-left: 4em;
  }
  footer .link-wrapper .split {
    visibility: hidden;
  }
  footer .link-wrapper .link-policy {
    margin-right: 1em;
  }
  footer .link-wrapper .link-terms {
    margin-left: 1em;
  }
}
